import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from 'react'
import HeaderPage from "./components/header";
import { Button, Row, Col, Accordion, Container } from "react-bootstrap";

function App() {
  const [showIframe, setShowIframe] = useState(false);
  const [urlIframe, setUrlIframe] = useState(false);

  const urlReact = 'https://pivotereact.vercel.app/'
  const urlAngular = 'https://dicio-pivote-angular-v16.vercel.app/'
  const urlVanilla = 'https://pivotevanilla-git-develop-diciodevs.vercel.app/'

  const mostrarIframeA = () => {
    setShowIframe(true);
    setUrlIframe(urlAngular);
  }

  const mostrarIframeR = () => {
    setShowIframe(true);
    setUrlIframe(urlReact);
  }

  const mostrarIframeV = () => {
    setShowIframe(true);
    setUrlIframe(urlVanilla);
  }
  return (
    <>
      <HeaderPage></HeaderPage>
      <Container>
        <Row>
          <Col md="2"></Col>
          <Col md="8">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Pivote React JS</Accordion.Header>
                <Accordion.Body>
                  Es una librería diseñada para una fácil integración, que
                  permite lanzar un proceso de DICIO, sin tener que pasar por su
                  infraestructura, permitiendo escuchar eventos del proceso
                  desde el sitio que lanza el flujo.
                  <div className="my-2 gap-2 text-right">
                    <Button
                      variant="primary"
                      size="md"
                      onClick={(e) =>
                        // window.open("https://pivotereact.vercel.app/", "_blank")
                        mostrarIframeR()
                      }
                    >
                      React JS
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Pivote Angular</Accordion.Header>
                <Accordion.Body>
                  Es una librería diseñada para una fácil integración, que
                  permite lanzar un proceso de DICIO, sin tener que pasar por su
                  infraestructura, permitiendo escuchar eventos del proceso
                  desde el sitio que lanza el flujo.
                  <div className="my-2 gap-2">
                    <Button
                      variant="primary"
                      size="md"
                      onClick={() =>
                        mostrarIframeA()
                      }
                    >
                      Angular JS
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Pivote Vanilla JS</Accordion.Header>
                <Accordion.Body>
                  Es una librería diseñada para una fácil integración, que
                  permite lanzar un proceso de DICIO, sin tener que pasar por su
                  infraestructura, permitiendo escuchar eventos del proceso
                  desde el sitio que lanza el flujo.
                  <div className="my-2 gap-2">
                    <Button
                      variant="primary"
                      size="md"
                      onClick={() =>
                        mostrarIframeV()
                      }
                    >
                      Vanilla JS
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col md="2"></Col>
        </Row>
        <br></br>
        <Row>
          <Col md="2"></Col>
          { (showIframe) ? 
            <Col md="8">
              <iframe
                src={urlIframe}
                id="inlineFrameExample"
                title="Inline Frame Example"
                width="100%"
                height="1000px"
              ></iframe>
            </Col>
            :
            <Col md="8"></Col>
          }
          <Col md="2"></Col>
        </Row>
      </Container>
    </>
  );
}

export default App;

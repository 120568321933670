import React, { Fragment } from 'react'

const HeaderPage = (props) => {
    return (
        <Fragment>
            <div id="header_container_component" style={{display:'flex',alignItems:'center',justifyContent:'center',height:'60px',backgroundColor:'black', marginBottom:'50px'}}>
                <div  style={{ zIndex: '99999', }}>
                    <nav>
                        <div>
                            <a ><img src="../images/admin_logo_v2.png" alt="DICIO Logo" height="20px" /></a>
                        </div>
                    </nav>
                </div>
            </div>
        </Fragment>
    );
}

export default HeaderPage;